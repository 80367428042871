// NotFound.scss

$primaryColor: #3498db;
$secondaryColor: #2c3e50;

.not-found-container {
  text-align: center;
  padding-top: 20vh;
}

.not-found-heading {
  font-size: 2.5em;
  color: $primaryColor;
}

.not-found-text {
  font-size: 1.5em;
  color: $secondaryColor;
  margin-top: 10px;
}
