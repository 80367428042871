@import "./reset";
@import "./carousel";

// Login page layout
$border-clr: rgba(35, 31, 32, 0.1);
.login-page {
  display: flex;
  padding: 16px;
  @media screen and (max-width: 540px) {
    padding: 20px 0px 0;
  }
  &__right {
    width: 45%;
    height: calc(100vh - 32px);
    @media screen and (max-width: 1100px) {
      display: none;
    }
  }
  &__left {
    width: 55%;
    min-width: 43vw;
    margin: 32px 0 30px 24px;
    border-bottom: 1px solid $border-clr;
    display: flex;
    flex-flow: row wrap;
    @media screen and (max-width: 1100px) {
      width: 100%;
      min-width: 0;
      margin: 15px;
    }
    &:before {
      content: "";
      width: 1px;
      background: $border-clr;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }
}

.logo-holder {
  height: 38px;
  width: 100%;
}
// Login form styling
.login {
  &-content {
    margin-left: -40px;
    background: white;
    position: relative;
    // height: calc(100% - 38px);
    width: calc(100% + 40px);
    // margin-top: -33px;
    @media screen and (max-width: 1100px) {
      margin-top: 0;
      min-height: calc(100vh - 200px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }
    &__wrapper {
      background-color: #ffff;
      position: relative;
      padding-bottom: 83px;
      width: 100%;
      @media screen and (max-width: 1100px) {
        padding-bottom: 0;
      }
      > form,
      .otp-verify {
        max-width: 540px;
        margin: auto;
        padding: 0 20px;
        .p-progress-spinner{
          height: 15px !important;
          svg{
            height: 20px;
          }
          &::before{
            display: none;
          }
        }
        @media screen and (max-width: 1100px) {
          padding: 0 20px 40px;
        }
      }
    }
  }
  &-form {
    max-width: 500px;
    margin: auto;
    // margin-top: 15%;
    .btn {
      font-size: 16px;
      font-weight: 700;
      line-height: 19.49px;
      letter-spacing: 0em;
      margin-top: 33px;
      padding: 21.4px 20px;
      color: rgba(239, 239, 239, 1);
    }
    label {
      color: #5f6a7c;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: inline-block;
      margin-bottom: 5px;
      line-height: 17.05px;
    }
  }
}

.country-code {
  display: flex;
  position: absolute;
  left: 15px;
  // top: 50%;
  // transform: translateY(-50%);
  top: 23px;
  z-index: 2;

  .p-inputgroup-addon {
    font-size: 18px;
    font-weight: 600;
    // color: #231f20;
    color: rgb(35, 31, 32);
    background-color: transparent;
  }
  i.pi.pi-phone {
    margin-right: 10px;
    font-size: 16px;
  }
  .p-dropdown-trigger {
    display: none;
  }
}
.enter-phone-number {
  font-size: 16px;
  position: relative;
  margin-bottom: 8px;
  .enter-number {
    font-size: 16px;
    padding: 15px 10px 15px 80px;
    height: 64px;
    font-size: 18px;
    font-weight: 600;
    color: #231f20;
    color: rgba(35, 31, 32, 1);
    border-radius: 2px;
    border: solid 1.5px rgba(95, 106, 124, 1);
    background-color: rgba(250, 250, 251, 1);
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
    &.p-filled {
      border-color: #2d3280 !important;
    }
    &::placeholder {
      color: rgba(95, 106, 124, 1);
    }
    &.error,
    &:enabled.error {
      border: 1.5px solid #f04142 !important;
      &.p-filled {
        border-color: #2d3280;
      }
      &.error {
        border: 1.5px solid #f04142;
        background: #f8f6f6;
      }
    }
  }
}
.Login-title {
  margin-bottom: 47px;
  h4 {
    font-size: 56px;
    letter-spacing: 0.56px;
    font-weight: 700;
    font-family: bebas-neue-pro, sans-serif;
    color: #030d1d;
    margin: 20px 0 12px;
    line-height: normal;
    font-size: 56px;
    font-weight: 700;
    line-height: 67px;
    letter-spacing: 0.01em;

    @media screen and (max-width: 540px) {
      font-size: 45px;
      margin-top: 65px;
    }
  }
  p {
    color: #5f6a7c;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: left;
    margin: 0;
    letter-spacing: 0em;
    span {
      color: #231f20;
      font-weight: 600;
    }
  }
}

.btn-submit {
  background-color: #2d3280;
  color: #fff;
  padding: 23px 20px;
  font-size: 16px;
  text-transform: uppercase;
  border: 0;
  width: 100%;
  font-weight: 700;
}

.error-message {
  color: #f04142;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}

.error-message__contact-admin {
  color: #231f20;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #5f6a7c;
  a {
    padding-left: 5px;
    color: #2d3280;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    &:hover {
      text-decoration: none;
    }
  }
}

.otp-input__wrapper {
  margin-bottom: 20px;
  > div {
    flex-wrap: wrap;
    margin-right: -15px;
    justify-content: space-between;
    @media screen and (max-width: 540px) {
      justify-content: flex-start;
    }
  }
  input {
    width: 64px !important;
    height: 64px;
    padding: 10px;
    border-radius: 8px;
    border: 1.5px solid rgba(95, 106, 124, 1);
    background: #fafafb;
    box-sizing: border-box;
    margin-right: 15px;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #231f20;
    @media screen and (max-width: 540px) {
      width: 40px !important;
      height: 40px;
      padding: 5px;
      margin-right: 5px;
    }
    &:focus-visible {
      border: 1.5px solid #2d3280;
      outline-color: #2d3280;
    }
  }
}

.resend-otp {
  margin-bottom: 35px;
  font-size: 14px;
  line-height: normal;
  color: #231f20;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  .resend-Link {
    cursor: pointer;
  }
  .timeout {
    color: #007a31;
    font-weight: 800;
    transition: 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
}

.otp-verify__wrapper {
  .Login-title {
    p {
      color: #5f6a7c;
      .edit-link {
        color: #2d3280 !important;
        transition: 0.3s ease;
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-decoration: none;
        text-align: left;
        position: relative;
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          bottom: 0;
          background-color: rgba(45, 50, 128, 1);
        }
        img {
          margin-left: 5px;
        }

        &:hover {
          text-decoration: none;
          opacity: 0.9;
        }
      }
    }
  }
}

.alert-icon {
  position: absolute;
  right: 20px;
  top: 23px;
  z-index: 9;
}
