// Carousel styling
.carousel-wrapper {
  .p-carousel-prev,
  .p-carousel-next {
    display: none !important; /* Hide the navigation arrows */
  }

  .p-carousel-item {
    height: calc(100vh - 32px);

    .slides-wrapper {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .carousel-caption {
        position: relative;
        z-index: 1;
        font-size: 60px;
        color: #fff;
        padding: 40px;
        font-family: "bebas-neue-pro";
        h5 {
          //   font-family: Bebas Neue Pro;
          font-size: 48px;
          font-weight: 400;
          line-height: 53px;
          letter-spacing: 0em;
          text-align: center;
        }
        h2 {
          //   font-family: Bebas Neue Pro;
          font-size: 144px;
          font-weight: 700;
          line-height: 158px;
          letter-spacing: 0em;
          text-align: center;
        }
        h4 {
          //   font-family: Bebas Neue Pro;
          font-size: 48px;
          font-weight: 700;
          line-height: 53px;
          letter-spacing: 0em;
          text-align: center;
        }
        h3 {
          font-size: 104px;
          font-weight: 700;
          line-height: 110%;
          letter-spacing: 0em;
          text-align: center;
        }
      }

      img {
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }
  }
  .p-carousel-content {
    position: relative;

    .p-carousel-indicators {
      position: absolute;
      left: 50px;
      right: auto;
      bottom: 20px;

      .p-carousel-indicator {
        .p-link {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          opacity: 0.5;
          transition: 0.3s ease;
          background-color: #fff;
          margin: 10px 8px;
        }

        &.p-highlight {
          .p-link {
            opacity: 1;
          }
        }
      }
    }
  }
}
