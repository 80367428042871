.partner {
  &-view {
    .container {
      max-width: 600px;
      margin: 0 auto;
      position: relative;
      // overflow-x: hidden;
      // overflow-x: hidden;
      // overflow-y: visible;
    }
    &__partner-task {
      background: #f4f4f9;
      padding: 22px 10px 20px 10px;
      min-height: calc(100vh - 70px);
    }
  }
  &-task {
    border-radius: 8px;
    border-left: 6px solid #abdeee;
    background: #fff;
    padding: 12px 16px;
    &.Sjælland {
      border-left: 6px solid #abaeee;
    }
    &.Fyn {
      border-left: 6px solid #efe8a9;
    }
    &.Jylland {
      border-left: 6px solid #e8c4fe;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-list {
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 15px;
      margin-bottom: 20px;
      &__heading {
        margin-bottom: 18px;
        padding-left: 12px;
        h2 {
          color: #030d1d;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 20px */
          margin-bottom: 8px;
          text-transform: capitalize;
        }
        p {
          color: #5f6a7c;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 14px */
        }
      }
    }
    &-priority {
      span {
        border-radius: 999px;
        background: rgba(225, 13, 0, 0.14);
        color: #e90505;
        font-size: 8px;
        font-style: normal;
        font-weight: 600;
        line-height: 8px; /* 100% */
        letter-spacing: 0.32px;
        text-transform: uppercase;
        padding: 6px 8px;
        display: inline-block;
        margin-bottom: 10px;
      }
    }
    &-details {
      &_title {
        display: flex;
        margin-top: 0px;
        align-items: center;
        margin-bottom: 5px;
        .title {
          color: #030d1d;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 19.2px */
          word-break: break-word;
          &-icon {
            margin-right: 10px;
          }
        }
      }
    }
    &-description {
      p {
        color: #5f6a7c;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 19.2px */
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        * {
          font-size: 12px !important;
          font-family: "proxima-nova", sans-serif;
        }
        pre {
          color: #5f6a7c;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 19.2px */
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: "proxima-nova", sans-serif;
        }
      }
    }
    &-deadline {
      display: flex;
      align-items: center;
      margin-top: 15px;
      justify-content: space-between;
      .deadline {
        margin: 0;
        .deadline__details {
          font-size: 12px;
        }
      }
      .partner-task-duration {
        display: flex;
        align-items: center;
        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0em;
          color: #030d1d;
        }
        .icon-time {
          margin-right: 5px;
          line-height: 0;
        }
      }
    }
  }
}

.partner-view__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  position: sticky;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 11;
  .header-left {
    display: flex;
    align-items: center;
    .logo {
      display: block;
      margin-right: 20px;
    }
  }
  .p-calendar {
    border-radius: 8px;
    border: 1px solid rgba(35, 31, 32, 0.04);

    display: flex;
    align-items: center;
    position: relative;
    input {
      background: #f5f5f5;
      border-radius: 8px;
      width: 164px;
      height: 40px;
      font-size: 14px;
      max-width: 100%;
      &::placeholder {
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #231f20;
      }
    }
    &:after {
      content: "\e930";
      font-family: primeicons;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-feature-settings: normal;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    .p-button-icon-only {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      top: 0;
      background: transparent;
      opacity: 0;
      z-index: 1;
      svg {
        display: none;
      }
    }
  }
  .user-Login-info {
    padding: 4px 10px 4px 4px;
    .user-avatar,
    .user-avatar img {
      width: 32px;
      height: 32px;
    }
    .dropdown-panel {
      width: auto;
    }
    i.icon {
      padding: 4px 5px;
    }
    .user-details {
      display: none;
    }
    .user-avatar {
      span {
        width: 32px;
        height: 32px;
        font-size: 15px;
      }
    }
  }
}

.no-data {
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 20px;
  }
}

.partner-task-priority.Maleropgave span {
  background: #8aff8a78;
  color: #12a712;
}
