$color_primary: #2d3280;

.btn {
  background-color: transparent; /* Green */
  //   border: solid 1px $color_primary;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: $color_primary;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 1.5rem 0;
  transition: 0.5s ease;
  cursor: pointer;
  border: 0;
  font-family: "proxima-nova", sans-serif;
  //   border: solid 1px $color_primary;
  &:hover {
    // background-color: $color_primary;
    opacity: 0.7;
  }
  // button theme : start
  &.link {
    padding: 23px 20px;
    color: rgba(0, 69, 219, 1);
    // text-decoration: underline;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
  }
  &.secondary {
    border: solid 1px $color_primary;
    color: $color_primary;
    &:hover {
      background-color: $color_primary;
      color: #fff;
    }
  }
  &.disabled {
    background-color: $color_primary;
    color: #fff;
    cursor: not-allowed;
    // pointer-events: none;
    opacity: 0.7;
  }
  &.primary {
    border: solid 1px $color_primary;
    background-color: $color_primary;
    padding: 23px 20px;
    color: #fff;
    &:hover {
      background-color: transparent;
      color: $color_primary;
    }
  }
  // button theme : End

  //   button size :start
  &.small {
    padding: 5px 10px;
    font-size: 12px;
  }
  &.medium {
    padding: 23px 20px;
    font-size: 14px;
  }
  &.large {
    display: block;
    width: 100%;
    font-size: 16px;
    padding: 23px 20px;
  }
  //   button size :End
}
