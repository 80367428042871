.header-row {
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f5f5;
  width: 100%;
  .header-row-content .col {
    display: block;
    text-align: center;
    box-shadow: -1px -1px 0px 0px #e0e0e0 inset;
    padding: 10px 5px;
    background-color: rgba(250, 250, 250, 1);
    &.today {
      background-color: #cdcdcd6b;
    }
  }
  span.day {
    font-size: 10px;
    font-weight: 500;
    color: #71717a;
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
  }
  span.date {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
.week,
.year {
  display: flex;
}

// .year {
//   width: calc(365 * (50px));
// }

.week > .col:first-child,
.week > .col:last-child {
  background-color: #ffdfdf;
}

.week-number {
  color: #000;
  text-align: center;
  // font-family: Proxima Nova;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  // padding: 5px;
  min-width: 50px;
  display: flex;
  position: relative;
  .week-text {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(0, 0, 0, 1);
  }
  &:nth-child(odd) {
    background-color: #cdcdcd;
  }
  &:nth-child(even) {
    background-color: #2d3280;
    .week-text {
      color: white;
    }
  }
}
.empty-col {
  position: absolute;
  width: 116px;
  height: 66px;
  display: flex;
  background: #f2f2f2;
  z-index: 34;
}
.header-row-content {
  padding-left: 116px;
}
