.dashboard-table-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  background-color: rgba(242, 242, 242, 1);
}

.col {
  padding: 10px;
  min-width: 50px;
  max-width: 50px;
  min-height: 36px;
  &.today {
    background-color: #d2f7c7 !important;
  }
}

// Main panel contents - styling
.main-panel {
  max-width: 100vw;
  overflow: auto;
  display: flex;
  align-content: baseline;
  // max-height: 500px;
  // max-height: calc(100vh - 200px);
  height: 100%;
  // resize: vertical;
  flex-wrap: wrap;
  &::after {
    content: "";
    position: absolute;
    width: 116px;
    height: 70px;
    display: flex;
    background-color: #fafafa;
    // background-color: red;
    z-index: 34;
  }
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  /* Track (the area where the thumb doesn't cover) */
  &::-webkit-scrollbar-track {
    background-color: rgba(233, 229, 229, 1);
  }

  /* Handle (the draggable part of the scrollbar) */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(197, 196, 196, 1);
    border-radius: 23px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(168, 168, 168);
  }
}

.header-row {
  position: sticky;
  top: 0;
  z-index: 5;

  .header-label {
    padding: 10px 10px 10px 20px;
    display: flex;
    align-items: center;
    width: 116px;
  }
  .year {
    width: calc(100% - 116px);
    position: relative;
  }
}
.year {
  position: relative;
}
.content-row {
  .col {
    background: #fff;
    // box-shadow: -1px -1px 0px 0px #f7f7f7 inset;
    box-shadow: -1px -1px 0px 0px rgba(224, 224, 224, 1) inset;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      height: 1px;
      transform: translateY(-50%);
      top: 50%;
    }
    &.weekend {
      background: #f2f2f2;
      box-shadow: 0px -1px 0px 0.5px #e5e5e5 inset;
      // .out-off-office {
      //   background: #dfdfdf;
      // }
    }
    .out-off-office {
      position: absolute;
      left: 0;
      width: 100%;
      // top: 28px;
      top: 0;
      bottom: 0;
      // letter-spacing: -0.3px;
      font-size: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f2f2f2;
      height: 20px;
      font-weight: 500;
      text-align: center;
    }
  }
  .row {
    display: flex;
    position: relative;
    .user-name {
      min-width: 116px;
      max-width: 116px;
      // background-color: #f2f2f2;
      background-color: #fafafa;
      position: sticky;
      left: 0;
      z-index: 3;
      display: flex;
      align-items: center;
      padding: 10px 15px 10px 25px;
      color: #71717a;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      justify-content: flex-start;
      word-break: break-word;
      min-height: 70px;
    }
  }
}

.bottom-layer {
  position: relative;
}

.row-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.task-time {
  background: #abdeee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  width: 49.8px;
  height: 16px;
  float: left;
}

.task,
.sub-task {
  background: #d3d3d3;
  border-left: 1px solid #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 16px;
  width: 50px;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  margin-bottom: 2px;
  &.first-child {
    z-index: 2;
  }
  &.zealand {
    background: #abaeee;
  }
  &.funen {
    background: rgba(238, 236, 171, 1);
  }
  &.jutland {
    background: rgba(238, 171, 231, 1);
  }
  &.complaint {
    background: #ff8e8e;
  }
}
.task {
  cursor: pointer;
  padding: 0 5px;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.first-child {
    position: relative;
    z-index: 2;
    color: rgb(0, 0, 0);
  }
}
.row-overlay-time {
  display: flex;
}
.subtask {
  position: relative;
  z-index: 1;
  background: #ccc;
  font-size: 10px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 1px;
  // float: left;
}
// week view -styling
.week-row {
  display: flex;
}

.task-wrap-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  padding: 10px 0;
  height: 100%;
  padding-top: 37px;
}

.task-wrap__relative {
  position: relative;
  height: 100%;
}
.task-wrap {
  // position: relative;
  position: absolute;
  width: 50px;
  margin: 1px 0;
  clear: both;
  overflow: hidden;
}
.task-duration {
  // position: absolute;
  top: 0;
  display: inline-block;
  color: #000;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  background: rgba(171, 222, 238, 1);
  // line-height: 12px;
  padding: 3px 0;
  width: 100%;
  span {
    color: rgba(0, 0, 0, 1);
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
  }
}
.task {
  // position: absolute;
  width: 100%;
}
.sub-task_wrap {
  position: relative;
}
.sub-task {
  position: absolute;
  top: 0px;
}
// unassigned table-----------------------------------------
.unassigned-table-wrap {
  // margin-top: 45px;
  margin-top: 15px;
  // margin-left: 40px;
  width: 100vw;
  overflow: hidden;
  .task-wrap-container {
    padding: 20px 0;
  }
  h3 {
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 28.6px;
    // margin-left: 40px;
    margin-left: 25px;
  }
  .content-row .row {
    // min-height: 152px;
  }
}
.unassigned-table {
  max-width: 100vw;
  overflow: auto;
  max-height: 525px;
  display: flex;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  /* Track (the area where the thumb doesn't cover) */
  &::-webkit-scrollbar-track {
    background-color: rgba(233, 229, 229, 1);
  }

  /* Handle (the draggable part of the scrollbar) */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(197, 196, 196, 1);
    border-radius: 23px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(168, 168, 168);
  }
  .category-name {
    min-width: 116px;
    max-width: 116px;
    background-color: rgba(242, 242, 242, 1);
    position: sticky;
    left: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    // padding: 10px 15px 10px 40px;
    padding: 10px 15px 10px 25px;
    min-height: 72px;
    color: #000;
    // font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    align-items: center;
    // justify-content: center;
    justify-content: flex-start;
    word-break: break-all;

    // span {
    // transform: rotate(-90deg);
    //   transform-origin: center;
    // }

    // color codes
    &.color-sjælland {
      background-color: rgba(171, 174, 238, 1);
    }
    &.color-fyn {
      background-color: rgba(239, 232, 169, 1);
    }
    &.color-jylland {
      background-color: rgba(232, 196, 254, 1);
    }
  }

  // task and subtask
  .task,
  .sub-task {
    min-height: 24px;
    background: rgba(216, 216, 223, 1);
  }
}

.task.false {
  background: transparent;
  color: transparent;
  border: none;
}

.task-time_wrapper {
  display: flex;
  margin-bottom: 10px;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: solid 1px #0b0b0b !important;
}
.p-inputtext:enabled:hover {
  border-color: #0b0b0b;
}

.main-panel {
  .col {
    padding-top: 19px;
    // padding-left: 3px;
    // padding-right: 3px;
  }
  .user-name {
    border-bottom: solid 1px rgb(224, 224, 224);
  }
}
.shared-task {
  .task {
    position: relative;
    span {
      position: relative;
      z-index: 2;
    }
    &::after {
      content: "";
      background-image: url(../../../assets/images/icons/pattern.svg);
      background-size: 10px;
      background-repeat: repeat;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      opacity: 0.3;
    }
  }
}

p.comment-info-text {
  font-size: 12px;
  padding-top: 2px;
  color: #be4141;
}
.task-date-time__col.notes.error textarea,
.task-date-time__col.time.error input {
  border-color: #be4141;
}
.share-task__partner--time.time.error input {
  border: 1px solid #be4141;
}

.share-task__partner--dropdown.error .p-dropdown {
  border: solid 1px #be4141;
}
.share-task__partner p.comment-info-text {
  padding-top: 5px;
}
