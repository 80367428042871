$clr-border: rgba(95, 106, 124, 0.18);
$clr-grey: #7f7f7f;
.site-header {
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $clr-border;
}

.user-Login-info {
  background: #f5f5f5;
  border-radius: 35px;
  padding: 6px 16px 6px 8px;
  display: flex;
  align-items: center;
  i.icon {
    padding: 4px 10px;
    img {
      width: 10px;
      &.menu-open {
        transform: rotate(180deg);
        transition: all 0.3s;
      }
    }
  }
}

.user-avatar {
  margin-right: 10px;
  &,
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.user-details {
  margin-right: 5px;
  max-width: 80px;
  h5 {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // line-height: 18px;
    letter-spacing: 0em;
    // text-align: left;
  }
  p {
    color: $clr-grey;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // line-height: 16px;
  }
}

.user-Login-info {
  position: relative;
  cursor: pointer;
}

.dropdown-header {
  display: flex;
  align-items: center;
}

.dropdown-panel {
  position: absolute;
  top: 100%;
  background: white;
  border: 1px solid rgba(197, 197, 197, 0.24);
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 20px 10px;
  right: 0;
  z-index: 7;
  li {
    padding: 4px 10px;
  }
  span {
    cursor: pointer;
  }
}

.user-avatar {
  span {
    text-transform: uppercase;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: #2d3280;
    color: #f5f5f5;
    border-radius: 50%;
  }
}
