section.filter {
  // padding: 15px 0 0;
}

// dashboard style
.filter {
  .container {
    // padding: 10px 40px 0px 40px;
    padding: 10px 40px 0px 25px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      padding: 10px 20px;
      flex-flow: row wrap;
      .date-filter {
        order: 3;
        width: 100%;
        display: flex;
        justify-content: right;
        margin-top: 23px;
      }
      .profile {
        order: 2;
      }
    }
  }
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1200px;
    .filter-label {
      padding-right: 20px;
      display: flex;
      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: rgba(95, 106, 124, 1);
      }
      i {
        margin-right: 10px;
        @media (min-width: 1200px) {
          display: none;
        }
      }
    }
    @media (max-width: 1200px) {
      .filter-col__wrapper {
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        &.active {
          opacity: 1;
          visibility: visible;
        }
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        background: white;
        z-index: 9;
        width: 100%;
        padding: 20px;
      }
      .filter-col {
        width: calc(50% - 20px);
        margin: 0 10px 15px;
        .p-dropdown {
          width: 100%;
        }
      }
    }
    @media (max-width: 767px) {
      .filter-col__wrapper {
        .filter-col {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
  &-col {
    // width:20%;
    margin-right: 20px;
    display: inline-block;

    .p-dropdown {
      max-width: 130px;
    }
    @media screen and (min-width: 1380px) {
      &:nth-child(1) {
        .p-dropdown {
          max-width: 178px;
        }
      }
      &:nth-child(2) {
        .p-dropdown {
          max-width: 178px;
        }
      }
      &:nth-child(3) {
        .p-dropdown {
          // width: 118px;
          max-width: 178px;
        }
      }
      &:nth-child(4) {
        .p-dropdown {
          max-width: 160px;
        }
      }
    }
    .p-dropdown {
      padding: 8px 55px 8px 15px;
      border: solid 1px #5f6a7c;
      border-radius: 4px;
      position: relative;
      background-color: rgba(250, 250, 251, 0);
      .p-dropdown-label {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        color: rgba(35, 31, 32, 1);
      }
      .p-dropdown-clear-icon {
        width: 12px;
        right: 30px;
      }
      .p-dropdown-trigger {
        position: absolute;
        right: 18px;
        width: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .p-dropdown:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 1px #000000;
      border-color: #000000;
    }
    .p-dropdown:not(.p-disabled):hover {
      border-color: #000000;
    }
  }
}

.p-dropdown-panel {
  background: #ffffff;
  color: #4b5563;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-dropdown-items-wrapper {
  overflow: auto;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 10px 15px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 5px 10px;
  border: 0 none;
  color: #4b5563;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
  font-size: 14px;
}

.date-filter {
  display: flex;
}
.date-filter-head {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .show-date {
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      opacity: 0.7;
    }
  }
}
.date-filter-arrows {
  display: flex;
}
.prev-arrow,
.next-arrow {
  width: 32px;
  height: 32px;
  background: #f5f5f5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover,
  &:focus {
    background: #e4e6ff;
  }
  img {
    width: 12px;
    height: 6px;
  }
}
.prev-arrow img {
  transform: rotate(90deg);
  transform-origin: 40% 50%;
}
.next-arrow img {
  transform: rotate(-90deg);
  transform-origin: 60% 40%;
}

.show-date {
  font-size: 16px;
  line-height: 112.5%;
  color: black;
  font-weight: 600;
  padding: 0 6px;
}

.date-filter-dropdown-arrow {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.active {
    img {
      transform: rotate(180deg);
    }
  }
  img {
    width: 12px;
    transition: 0.3s ease;
    transform-origin: center;
  }
}

.date-filter-dropdown {
  position: absolute;
  background: white;
  border: 1px solid rgba(197, 197, 197, 0.24);
  border-radius: 16px;
  box-shadow: 0px 11px 24px 0px rgba(9, 22, 42, 0.06),
    0px 44px 44px 0px rgba(9, 22, 42, 0.05),
    0px 100px 60px 0px rgba(9, 22, 42, 0.03),
    0px 177px 71px 0px rgba(9, 22, 42, 0.01),
    0px 277px 78px 0px rgba(9, 22, 42, 0);
  top: 100%;
  right: 0;
  padding: 24px;
  width: 556px;
  z-index: 6;
  * {
    font-size: 16px;
    font-weight: 600;
    line-height: 112.5%;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #000000;
    border-color: #000000;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #000000;
  }
  h4 {
    color: #231f20;
    margin-bottom: 16px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    li {
      width: calc(100% / 3);
      border-bottom: 1px solid #d7d7e9;
      border-right: 1px solid #d7d7e9;
      a,
      span {
        padding: 13px 16px;
        text-decoration: none;
        display: block;
        color: #5f6a7c;
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          background-color: #2d3280;
          color: #ffffff;
        }
      }

      &.active {
        span {
          background-color: #2d3280;
          color: #ffffff;
        }
      }
      &:nth-child(-n + 3) {
        border-top: 1px solid #d7d7e9;
      }
      &:nth-child(3n + 1) {
        border-left: 1px solid #d7d7e9;
      }
    }
  }
}

.date-range-selector {
  display: none;
  &.active {
    display: flex;
  }
  flex-wrap: wrap;
  padding-top: 30px;
  margin: 0 -10px;
  > div {
    width: Calc(50% - 20px);
    margin: 0 10px;

    .p-dropdown,
    .p-calendar {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #5f6a7c;
      background: #fafafb;
    }
    .p-dropdown-trigger {
      margin-right: -10px;
      svg {
        width: 12px;
      }
    }

    label {
      color: #5f6a7c;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.14px;
    }
  }
}
