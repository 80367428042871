.deadline {
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  padding: 6px 12px 6px 12px;
  background: rgba(241, 242, 244, 1);
  border-radius: 8px;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  &__svg-icon {
    margin-right: 10px;
    position: relative;
    z-index: 1;
    line-height: 0;
  }
  &__progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(227, 229, 233, 1);
  }
  &__details {
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: rgba(95, 106, 124, 1);
    span {
      color: rgba(35, 31, 32, 1);
    }
  }
}
