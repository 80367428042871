.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* You can adjust this to control the vertical alignment */
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(195, 195, 195, 0.25);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  animation: spin 1s linear infinite;
}
